const style = {

	zones: {
		fillRule: "evenodd",
		stroke: "lightGrey",
		strokeWidth: "0px",
		fill: 'transparent'
	},
	dots: {
		radius: 5,
		fill: {
			picnic: "yellow",
		}
	}


}

export default style