import * as React from "react"

function MapMT(props) {
	return (<g><path
	d="M288.85 801.85l17.4 20.4v.6l3.6 3.6 4.8 3.6 2.4.6 6 1.2h2.4l3-.6 2.4-.6 1.2-.6 61.2-31.8 1.2-.6 7.2-4.2-4.8-7.2-.6-.6-13.8-20.4-16.8-24-4.8-7.2-80.4 55.8 7.2 10.2 1.2 1.8M312.85 473.65l-2.4 1.2-34.2 13.2-5.4 1.8-67.2 25.8-4.2 1.8-10.8 4.2-4.8 1.8-39.6 15.6h-1.2l-1.2-.6v-.6l-12-30.6-1.8-5.4-4.8-11.4v-1.2l1.2-1.2 9-3.6h1.2l.6.6 15.6 40.2.6.6h.6l82.8-31.8 40.2-15.6 22.8-9 1.8-.6 20.4-8.4 14.4-5.4h1.8l2.4 1.2 15 14.4 1.8 2.4 2.4 2.4 2.4 1.8 13.2 13.2-7.8 7.8 3 3 7.8-7.8 5.4 5.4-7.8 7.8h-.6v-.6l-40.8-39-.6-.6h-.6l-7.8 3-10.8 4.2M318.25 662.05l13.8 19.8 3.6.6 3.6 1.2 2.4 1.2 4.2 1.8 6 3.6-6 1.8-3 1.2 1.2 2.4h-1.2v1.8l11.4 16.2 16.2 24 .6.6h1.2l.6-.6 1.8 1.8 5.4-4.2 1.8-1.8 18 15 12 9 12.6 1.8 7.2 13.8 1.2-.6 2.4-2.4 33-36.6 9-9h-.6l-2.4 1.2-1.2-.6h-.6l-6-6.6-1.2-1.2-2.4-3-6-8.4-3-4.8 12-3.6 24 3 6 3 34.2-37.2-6.6-6.6-10.2-.6v-15.6h10.8l4.8-4.2-21.6-21-6 6.6-12.6-10.8-1.2-.6-1.8.6-6 3-5.4 1.2-4.8.6-6.6-1.8-5.4 6 1.8 1.8-5.4 5.4-1.8-1.8-4.2-3.6-10.8 11.4-10.2 10.2-9.6-9-1.8 1.2-3 .6-2.4.6-4.8-1.2-9.6-4.8-4.2-3-3.6-3.6-2.4-3.6-9.6-12-1.2 1.2-56.4 49.2"
	fill="none"
	stroke="#adc191"
	strokeWidth={0.5}
	/>
	<path
		d="M461.65 721.45v.6l-3.6 7.2v.6l-.6.6-1.8 4.2-18.6 11.4-8.4 2.4-2.4.6-3 .6-7.2-3.6-.6-.6v-.6l-1.2-1.2-.6-3.6v-10.2l.6-4.8 2.4-9.6 8.4-4.2 21.6-1.2 4.2-3 7.8 10.8 3 3.6M809.05 288.25l-7.2 12-3 23.4-8.4 7.8-50.4-49.8-2.4 2.4-1.8-2.4-8.4 8.4-15.6-15.6 5.4-6 1.2-.6 1.2-1.2 1.2-.6h3l6 1.8 79.2 20.4M453.25 524.05l-9.6 9v.6l-.6.6-30-29.4h3l2.4 1.8 6 3h1.8l5.4-1.8v-.6l3.6-1.8 1.8-1.2 2.4-1.2 3-.6h5.4l2.4.6 3 .6 2.4 1.2 12 4.2-14.4 15"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M470.65 498.25l4.2 3.6-5.4 5.4-26.4-25.8-1.8-1.8-25.2 25.2h-.6l-4.8-4.8 22.8-23.4h-.6l7.8-8.4 30 30M575.05 383.05l8.4-9 9 8.4-11.4 11.4-5.4-.6-19.2-18.6 4.8-5.4 13.8 13.8M551.05 487.45l-19.8-19.8-3-2.4 46.2-46.8-23.4 24.6-1.8 3-1.8 3.6-1.2 3.6-.6 4.2-.6 3.6v7.8l1.2 3.6.6 3.6 1.2 3.6 1.8 3.6 2.4 3-1.2 1.2M795.85 377.65l-5.4-1.2.6-1.2 18.6-18.6 2.4 2.4-16.2 18.6M740.05 152.65l6.6 1.8 71.4 18-2.4 7.8-10.8-3-63.6-16.2-3-1.2-26.4-6.6h-1.2v-1.2l-1.2-.6-.6 1.2-1.2-.6-417-107.4 1.8-7.2 447.6 115.2M715.45 301.45v-.6l3 3-1.8 1.8 55.2 54.6-.6 1.2 3 3 1.2-.6 1.8 1.2-1.2 1.2 3.6 3 .6-.6 1.8 1.2-1.2 1.2 3.6 3 .6-.6 3 2.4-6.6 7.2 1.8 1.8 1.2 4.8-4.8 6-.6-.6-73.8 83.4-2.4 2.4-75.6 76.8-1.8 3-6.6-7.2-.6-1.2h-.6l1.8-1.8-1.8-1.8-1.8 1.8-7.8-7.8 1.2-.6 6 2.4 3.6 1.2h10.2l3-.6 3.6-1.2 3-1.2 3-1.8 3-2.4 1.8-1.2 58.8-60 59.4-67.2 1.8-2.4 1.2-3 1.2-3.6 1.2-3v-10.2l-.6-3.6-1.2-3.6-1.2-3-1.8-3-1.8-2.4-2.4-3-1.2-1.2-44.4-43.8-2.4-2.4-3-2.4-2.4-1.8-3-1.8-3.6-1.2-3-.6-3.6-.6h-6.6l-3.6.6-3 .6-3 1.8-3 1.2-3 2.4-36 36h-.6l72.6-74.4 3 2.4 1.2 1.8-5.4 5.4 7.2 6.6-1.2.6 2.4 3"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M627.25 551.65h.6l-3.6-3.6-.6.6 3.6 3M756.85 371.05l2.4 2.4 3.6 4.8 1.8 3 .6 3 1.2 3v9l-3.6 9-1.8 2.4-56.4 64.8-49.2 49.8-1.8 1.8-2.4 3-6 4.8-3 1.8-3.6 1.8-3.6 1.2-10.8 1.8-4.2-.6-7.2-1.2-3.6-1.8-3.6-1.2-46.8-46.2h-.6l-1.8-2.4-2.4-3-1.8-3-2.4-7.2-.6-3v-7.2l1.2-7.2 1.2-3.6 5.4-9 2.4-2.4 18-18.6 49.8-51 43.8-44.4 1.2-1.2 2.4-1.8 3-1.8 2.4-1.2 3.6-1.2 6-1.2h3l3.6.6 3 .6 6 2.4 2.4 1.8 4.8 4.8 44.4 43.8M438.85 369.85l1.2 1.2 1.8-1.8-1.2-1.2 17.4-18 1.8 1.2-26.4 27-1.2-1.8 6.6-6.6M583.45 186.85l-10.2-3 2.4-10.8-21-5.4-1.8 4.8h-.6l.6-2.4.6.6v-.6l-1.2-.6-1.2 2.4-5.4-1.2 3-13.8 5.4-19.8 76.2 19.8-3.6 15.6-9.6-1.8-1.8 6-9-2.4-6.6 7.2v15.6l-18-4.2 1.8-6"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M564.25 160.45l-5.4 3-.6 3.6v.6h.6l16.2 3.6h2.4l1.2-1.2 1.2-5.4-15.6-4.2M353.05 161.05l-54-14.4-1.2-.6v-1.2h.6l.6-.6 64.8 16.8 37.8 9.6.6 1.2.6.6-.6 1.2h-1.2l-48-12.6M795.85 337.45l6-6 3.6-29.4 7.8-12.6 18 4.8 4.2 6.6-10.2 38.4-5.4 1.2-9.6 10.8-14.4-13.8M353.05 344.05l-6-6.6-9 9-1.8-4.2 1.2-.6-35.4-90.6 7.8-9 .6-1.2v-1.8l-3.6-15.6-.6-3-1.2-1.8-5.4-3.6-4.2-1.2-5.4-.6-4.2.6-1.8-4.8 4.2-1.2h4.2l22.8.6h33l83.4 1.2 12.6 43.8-1.8 1.2-1.2.6-.6.6-.6 1.2v.6l-1.2 6.6-27-26.4-.6-.6 4.8-4.8-21.6-21-20.4 21.6 21 20.4 10.2-10.2 27 26.4-17.4 19.2-.6 1.2v3l.6.6 1.2 2.4-20.4 21-4.8 4.8-30 30.6-4.8-5.4-3-3"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M330.85 161.65l-35.4-10.2-2.4-1.2-1.2-1.2-.6-1.2v-4.8l1.2-1.8 1.8-1.2 1.2-.6 1.8-.6 36.6 9.6 36.6 9 36.6 9.6 2.4 3.6v2.4l-.6 1.8-2.4 3-3.6 1.2-72-17.4"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M402.25 173.65l.6-1.2-.6-.6-.6-1.2-37.8-9.6-64.8-16.8-.6.6h-.6v1.2l1.2.6 54 14.4 48 12.6h1.2M404.65 502.45h-2.4l-41.4-40.8-34.8-34.2v-2.4l78.6 77.4M434.05 206.65l-1.8-4.2h17.4l1.2.6 3 1.2 3 .6 2.4.6 3 .6 1.8 2.4 1.2 1.2-13.2-1.2h-12l-6-1.8M840.85 267.85l6 1.8-.6 1.8-.6 3.6-6.6 1.8-14.4-3.6 1.2-5.4 14.4 3.6.6-3.6M381.85 374.65h.6l-.6.6h-.6l-18 18-3-3 18.6-19.2 3 3.6M360.25 396.25l-3.6 4.2.6.6-.6.6-.6-.6-8.4 9-3.6-3.6 13.2-13.2 3 3"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M352.45 404.65l2.4-2.4-1.8-1.2-1.8 2.4 1.2 1.2M212.05 267.85l.6.6 1.8 5.4.6 1.2h-4.2l-1.2-1.2-1.8-1.2-.6-1.2-1.2-1.2 1.8-1.2 4.2-1.2M468.85 339.25l10.2-10.8 1.8 1.8-10.2 10.2-1.8-1.2M330.85 420.25l3 3.6-.6.6-3-3 .6-1.2M444.25 242.65l-7.8-31.2 22.8 1.2 10.8.6 11.4 1.2 12.6 1.8-44.4 45.6-5.4-19.2M455.65 573.85l-1.2 1.2-3.6-4.2-1.8 1.8-9-8.4-22.2 22.8-2.4-3 24.6-24.6 15.6 14.4"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M438.25 565.45l.6-.6-1.2-1.2-.6.6 1.2 1.2M159.25 153.85l-10.2-3-6 23.4-3.6 1.2.6 1.2-8.4 3.6-1.8-4.8v-1.2h.6l7.8-3-3.6-9-7.8 3.6h-.6l-.6-.6-1.2-3.6v-.6l.6-.6 7.8-3-3.6-8.4-7.8 3h-.6v-.6h-.6l-1.2-4.2-.6-1.2-.6-1.8v-3.6l1.2-1.2.6-1.8 9-16.8 22.8-7.8 8.4-9.6 5.4-11.4v-.6l-1.2-2.4-.6-3-.6-2.4v-6l.6-2.4 1.2-3-8.4-2.4 6-11.4 3 1.8 12-22.8 1.2-1.8 1.2-1.2 1.2-.6 1.8-1.2h3.6l1.8.6h2.4l-31.2 120.6M132.85 479.65v.6l-.6.6-9 3.6h-1.2l-1.2-.6v-.6l-3.6-9 1.8-1.2-.6-2.4-2.4 1.2-.6-1.2-1.8-5.4-10.2-26.4-2.4-5.4-10.2-26.4-1.8-4.8-12.6-33-1.8-4.8v-1.8l1.2-.6 9-3.6h.6l.6.6 46.8 120.6"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M173.65 157.45l-14.4-3.6 31.2-120.6 5.4 1.8-1.8 6-.6 3-1.2 4.2-25.2 99 8.4 2.4-1.8 7.8M164.05 200.05l.6 6.6.6 3.6-6.6 13.8-10.8-27.6-8.4 3h-.6l-1.8-5.4-1.8-4.8 8.4-3.6 21.6-8.4-1.2 22.8M173.65 288.25l-3.6-9.6v-.6l.6-.6 7.8-3-15-37.8 9.6 1.8 2.4 6 3 5.4 3.6 5.4 3 4.2 1.2 1.8-3 7.2-6 13.2-3.6 6.6M389.65 510.25l-4.8 4.8h-1.8l-5.4-4.8-1.2 1.2-1.2-.6-.6-1.2h.6v-.6l8.4-8.4 1.2 1.2h.6l4.2 8.4M194.05 303.85l-11.4 4.2h-1.2l-.6-.6-6-15 6-10.8 3.6-6.6 2.4-6.6 1.8-3.6 17.4 17.4h.6l3 1.8 2.4 2.4 2.4 1.8 1.8 3 1.8 2.4.6.6-24.6 9.6M199.45 270.25l-12-13.2-13.8-25.8v-1.2l-3.6-12-1.2-6-.6-6-.6-6.6v-6l5.4 1.2 28.2 72.6-1.2 1.2-.6 1.8"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M214.45 275.05h.6v.6l-6.6 2.4h-1.2l-3-3-3-7.8-28.2-72.6-4.8-12-.6-1.2 1.2-.6 4.2-1.8 34.2 88.8.6 1.2-1.8 1.2 1.2 1.2.6 1.2 1.8 1.2 1.2 1.2h3.6M249.85 282.25h.6v-.6.6l-16.2 6-12 4.8-.6-.6-1.8-3-2.4-3-4.8-4.8-3-1.8h-.6l6.6-3 28.2-10.8 3 7.2h-1.2l3.6 7.8v.6l.6.6M478.45 276.25l-6-6 3-3 24-24.6.6-.6 1.2-.6h1.2l2.4 1.2 3.6 3-30 30v.6M254.65 63.85l-3.6-1.2-3.6 14.4-10.8 42-.6-.6-.6 1.8-3 10.2 9.6 2.4v1.2l6 1.2-.6 1.2-16.2-4.2-6.6 5.4-4.8 7.8 4.2 12 7.2 8.4h4.8l8.4-20.4 17.4 4.2-2.4 5.4-3.6 9-3 8.4-6 24-3.6 13.8v10.2l1.8 3.6-.6 1.2v1.2l-1.2.6-.6.6-1.2.6h-1.2l-2.4-1.2-.6-1.2-.6-2.4-17.4-44.4v-1.8l3.6-6.6.6-.6h-.6l-9.6-24.6v-1.2l1.8-6 .6-3v-.6l24-89.4v-.6l.6-.6 1.2-.6h.6l15.6 4.2h.6v1.2l-1.8 8.4-1.8 6.6M745.45 221.65l4.8-19.2 2.4-9v-.6l1.2-2.4 1.2-1.8 16.2 4.2-9 36 10.8 2.4 9-36 11.4 3-12.6 48 1.8.6-.6.6-3 13.8-.6.6v.6l-1.2.6v.6h-3l-23.4-6-.6-.6-1.2-.6-1.8-1.2-3.6-3.6-2.4-4.8-.6-2.4v-4.8l.6-3 4.2-15M477.85 459.25l-30-29.4-.6-.6 12 4.2 4.8 4.8 11.4 10.8 2.4 2.4v7.8M497.65 479.05l-14.4-14.4 8.4.6 9.6 9.6-3.6 4.2M474.85 344.65l-2.4-2.4-2.4-1.8h.6l10.2-10.2 4.2 4.2-10.2 10.2M431.65 389.05v-.6l-1.8-1.2-7.8 7.8-4.2-4.2 1.2-.6 5.4-6 7.8-7.8 1.2 1.8 26.4-27-1.8-1.2 5.4-5.4 3.6 3 2.4 3-37.8 38.4"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M423.25 396.25l-1.2-1.2 7.8-7.8 1.8 1.2-1.8 1.8-6.6 6M816.85 272.05l-19.8-5.4-1.2 2.4-3.6-.6-.6-.6-1.2-.6-.6-1.2v-2.4l1.2-4.8 27.6 6.6-1.8 6.6M614.05 231.25l-.6 4.2h1.8l-15 16.2 14.4 14.4-4.8 4.8-16.8-16.8-3-3.6-4.2-3.6-2.4 2.4-22.2-21-3-3-5.4 6-15-15-12.6 13.2-3-2.4-1.2-1.2-.6-1.2v-1.8l.6-1.2.6-1.8 4.2-4.2 2.4-1.8 3-1.2 3.6-.6 4.2-.6 75 19.8M269.05 132.25l-1.8 4.8-.6 1.8-3.6-1.2 2.4-9-3-.6 4.8-18 .6.6v.6l.6 1.2.6.6h1.2l.6-.6.6-1.2 3.6-14.4v-1.2l-.6-.6-1.2-.6-1.2.6-.6.6 2.4-9 6.6 1.8-11.4 43.8M276.85 79.45h-1.2l3-9.6-24-6 1.8-6.6 22.2 5.4 4.2 1.2-3.6 14.4v.6l-.6.6h-1.8M697.45 257.05h.6l1.8.6 1.8 1.2 1.2 1.2.6 1.8.6 1.2v3.6l-1.2 3.6-1.2 1.2-26.4 27-21.6-21.6-1.2 1.2-7.8-6.6-6 6.6-6.6-6.6-15.6 16.8-7.8-7.8 5.4-6-2.4-2.4 6-6.6-1.8-1.2 21.6-22.8 60 15.6"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M656.05 272.05l1.8 3 2.4 2.4.6 1.2 16.2 12.6 12.6-17.4v-1.8l-.6-1.8-1.8-.6-1.2-.6-2.4-.6-1.8-1.2-21.6-9.6-4.2-1.8-4.2-2.4-10.2-2.4-2.4 7.8v1.2l.6 1.2.6.6.6 1.2h1.2l8.4 2.4 2.4.6 1.2 3 1.8 3M587.65 404.65l44.4-45.6.6.6-45 45M113.65 185.05l7.8-3h.6l.6.6 1.2 3-8.4 3.6 4.8 12 6.6-2.4-3.6-9 1.8-.6 5.4 13.2v1.2h-.6l-7.8 3 10.2 25.8 7.8-3h.6v.6h.6l3 9-11.4 4.2-6-16.2-10.8 4.2 3 7.8-6.6 2.4v.6-.6l-1.8-3.6-1.8-4.8-.6-1.2-10.2 3.6v.6l-13.2-33 21-38.4 1.2-1.8 1.8-1.2 1.2-.6h1.8l1.2.6h.6l1.8 1.8v.6h-.6v.6l-7.8 3 6.6 17.4M152.05 284.65l8.4-3h.6v.6l1.2 3 7.2 18.6v1.2l-1.2 4.2-1.2 4.8h-.6v.6h-.6l-45 17.4-9.6-25.2 16.8-6.6 4.8-1.8 1.2-.6-.6-1.8 2.4-1.2-3-7.8 6-1.8-7.8-22.2 19.8-7.8 1.2 3.6v1.2l-8.4 3 8.4 21.6M99.85 101.05l-9-8.4v-.6l-.6-.6.6-1.2 16.2-18.6 6.6 3.6-13.8 25.8M54.25 164.65v.6h-1.2l-13.8-12.6 5.4-6.6 7.2 7.2 7.2-8.4 6.6 6v1.2l-11.4 12.6M54.25 172.45l1.2 1.2v1.2l-.6.6-4.2 7.2-4.8 9.6-3.6-2.4h-1.2l4.2-5.4v-1.2l-.6-3.6v-.6l-.6-.6-12-9-1.2-.6-.6.6-3.6 4.8v.6l-4.8 6.6-.6.6h-.6l-1.8 3v1.2l3 7.2.6 1.2.6.6 8.4 6h1.2l6-8.4.6.6 3.6 3-8.4 12.6-1.8 1.8v1.2l3.6 3.6-7.2 8.4 5.4 4.8 7.2-7.8 3 2.4-7.8 8.4 5.4 4.8 7.8-8.4 5.4 5.4h.6l11.4 28.2v.6l1.8 3.6-.6.6 9 22.2 2.4 6.6 7.8 20.4 19.2-7.8 10.2 25.2-22.8 9-12 4.8h-.6v1.2l.6 2.4h.6l-1.2 1.2-9 3.6h-1.2l-1.2-1.2-5.4-15-2.4-5.4-9.6-25.2-2.4-5.4-44.4-114.6 29.4-32.4 18.6 16.2M284.05 830.05l-7.2-13.2 15-.6 1.2 1.8v1.2l-.6.6-1.2.6 2.4 4.8-9.6 4.8M264.85 749.65l-1.8 1.2-2.4 2.4-.6 1.8v3.6l1.2 3.6 4.8 7.2-3-5.4 6-3.6-2.4-3.6 11.4-7.8-1.2-1.8-3-4.2-9 6.6M289.45 813.25h-14.4l-27-51v-.6l.6-.6 3-2.4 37.8 54.6"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M92.65 233.65l-10.2 4.2 1.2 3.6-6 2.4-2.4.6-8.4 3.6h-.6l-6-16.2 5.4-1.8 4.2-1.8h1.8l4.2-7.8 6.6-12.6 10.2 25.8M132.25 48.85l-3.6-1.2-8.4 15h1.2l-6 10.8-7.2-3.6 22.8-26.4 3.6.6-2.4 4.8M88.45 120.85l2.4-3-10.2 19.2-6-4.8v-1.8l13.2-14.4.6 1.2-1.8 1.8 1.8 1.8M59.65 176.05v-3l-.6-1.2-.6-1.8-1.2-.6v-.6l-.6-.6.6-.6 18-20.4-15.6 28.8M91.45 116.65l-1.2-1.8-.6.6-.6-.6 9-10.2-6.6 12M290.65 36.85l-1.8 7.2-1.8-.6-52.2-13.2-58.8-15-3-1.2h-1.2v.6l-12.6 24h-.6l-.6.6h-1.8l-13.8-7.2 24.6-27 123.6 31.8M766.45 498.25l-8.4 8.4-3-3h.6l-29.4-29.4h.6l.6-.6 5.4-.6h.6l.6-.6 1.8-2.4 7.2 6.6 16.8-18 1.8 2.4.6.6 15.6 15 33-33 1.8 1.8 5.4-6 3 3-54.6 55.8M453.25 561.85l4.2 4.2 1.8 2.4h.6v1.2l-4.2 4.2-15.6-14.4-24.6 24.6-4.2-3.6 23.4-21 7.8-6.6h1.8l9 9M486.85 571.45l-16.8-16.2 1.8 1.2h2.4l.6.6h3l.6.6 11.4 10.8-3 3M740.05 281.65l50.4 49.8-1.8 1.8-51-49.2 2.4-2.4M788.05 375.85l-3-2.4-3-3.6-1.8-1.2-3-3.6-1.8-1.2-3.6-3.6-55.2-54.6 1.8-1.8 71.4 70.2-1.8 1.8M826.45 238.45l.6-3.6 21.6 4.8-1.2 3.6-21-4.8M825.85 267.85l.6-2.4.6-1.2h1.8l11.4 3 .6.6-.6 3.6-14.4-3.6M818.65 265.45l-27.6-6.6.6-3.6h1.2l26.4 6.6v1.8l-.6 1.8M426.85 170.65l-15-4.2-.6 1.2-1.8 16.8v2.4l.6 1.8v1.2l1.2 2.4 1.2 1.8 1.2 1.2 7.8 6 6.6-.6 1.8 5.4-80.4-.6h-2.4l-31.2-.6-21.6-.6h-3.6l-3.6.6-5.4 1.8h-.6l-1.2-.6-.6.6h-.6l-31.8 12.6v-8.4l2.4-9.6 1.2-4.2 6-23.4 1.8-5.4 3-8.4 3.6-8.4 1.8-3 2.4-5.4 1.8-4.8 1.8-5.4 3-13.2 154.8 39-3.6 12"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M402.85 179.05l3.6-1.2 2.4-3 .6-1.8v-2.4l-2.4-3.6-36.6-9.6-36.6-9-36.6-9.6-1.8.6-1.2.6-1.8 1.2-1.2 1.8v4.8l.6 1.2 1.2 1.2 2.4 1.2 35.4 10.2 72 17.4M492.85 483.85l-18 18-4.2-3.6 18.6-18.6 4.2-4.8 4.2 4.2-4.8 4.8"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M486.85 488.65l4.8-4.8-2.4-3-5.4 4.8 3 3M173.05 238.45l-9.6-1.8-4.8-12.6 6.6-13.8.6 6.6 5.4 18 1.8 3.6M615.25 221.65l-39-10.2 5.4-18.6 18 4.2 7.2 2.4-1.2 3.6h.6l3.6.6 7.2 2.4 3 .6-3 12.6-.6 1.8v.6h-1.2M570.85 191.65l1.2-5.4 9 2.4-6.6 22.8-37.2-9.6 3.6-15 4.8 1.2 1.8-7.2 3 .6-1.2 4.8 21.6 5.4M199.45 641.05l5.4-3-38.4-99.6-6.6 2.4 39.6 100.2M309.85 660.85l5.4-3.6-34.2-49.2-6 3 34.8 49.8M199.45 641.05l2.4 6 79.2-39-3.6-5.4-78 38.4"
		fill="none"
		stroke="#adc191"
		strokeWidth={0.5}
	/>
	<path
		d="M137.05 28.45l-55.8 62.4-81 90.6 142.2 365.4 190.8-74.4 90.6 88.8-137.4 121.2-19.2-20.4-55.2 38.4 28.8 54.6 31.2 60 12 22.8 21 43.2 174.6-90 81.6-84 34.2-34.2 344.4-349.8-2.4-3h-.6l2.4-9.6v-.6l2.4-9 3 .6 4.2 1.2 7.2 2.4 24.6-98.4-4.2 3.6-814.2-210-25.2 28.2 25.2-28.2"
		fill="none"
		stroke="#fff"
		strokeWidth={0.5}
	/>
	<g>
		<g fill="none" stroke="#f2f2f2" strokeWidth={3} strokeMiterlimit={10}>
		<path d="M189.85 367.45l-12-31.2 12 31.2-74.4 28.8" />
		<path d="M189.85 367.45l-74.4 28.8-12-31.2" />
		<path d="M115.45 396.25l-12-31.2 74.4-28.8M103.45 365.05l74.4-28.8M221.05 447.25l-12-31.2 12 31.2-73.8 28.8" />
		<path d="M221.05 447.25l-73.8 28.8-12-31.2" />
		<path d="M147.25 476.05l-12-31.2 73.8-28.8M135.25 444.85l73.8-28.8M540.85 175.45l7.2-26.4-7.2 26.4-.6 3.6" />
		<path d="M540.85 175.45l-.6 3.6-32.4-8.4M540.25 179.05l-32.4-8.4-4.8-1.2" />
		<path d="M507.85 170.65l-4.8-1.2-67.8-17.4" />
		<path d="M503.05 169.45l-67.8-17.4 10.2-40.2" />
		<path d="M435.25 152.05l10.2-40.2 105 27" />
		<path d="M445.45 111.85l105 27-2.4 10.2M550.45 138.85l-2.4 10.2M596.05 309.25l17.4-18-17.4 18 31.2 30M596.05 309.25l31.2 30-6 6M627.25 339.25l-7.8 7.8M621.25 345.25l-5.4 5.4M619.45 347.05l-3.6 3.6-23.4 24M615.85 350.65l-23.4 24-.6.6" />
		<path d="M592.45 374.65l-.6.6-.6-.6M591.85 375.25l-7.8-7.8M591.25 374.65l-7.2-7.2-6 6" />
		<path d="M584.05 367.45l-6 6-2.4-3" />
		<path d="M578.05 373.45l-2.4-3-10.8-10.2" />
		<path d="M575.65 370.45l-10.8-10.2-6 6" />
		<path d="M564.85 360.25l-6 6-21-21" />
		<path d="M558.85 366.25l-21-21-22.2-21.6" />
		<path d="M537.85 345.25l-22.2-21.6 6-5.4" />
		<path d="M515.65 323.65l6-5.4-6-6M521.65 318.25l-10.8-10.8M515.65 312.25l-4.8-4.8-18-17.4M510.85 307.45l-18-17.4-14.4-14.4" />
		<path d="M492.85 290.05l-14.4-14.4 29.4-30" />
		<path d="M478.45 275.65l29.4-30 19.2 18.6M507.85 245.65l19.2 18.6 8.4-8.4" />
		<path d="M527.05 264.25l8.4-8.4 20.4-21.6M535.45 255.85l20.4-21.6 3 3M555.85 234.25l12 12M558.85 237.25l9 9 12.6 12" />
		<path d="M567.85 246.25l12.6 12 5.4-5.4" />
		<path d="M580.45 258.25l5.4-5.4.6 1.2" />
		<path d="M585.85 252.85l.6 1.2 3.6 3M586.45 254.05l3.6 3 17.4 17.4" />
		<path d="M590.05 257.05l17.4 17.4-4.8 6M607.45 274.45l-4.8 6 10.8 10.8M602.65 280.45l10.8 10.8M572.65 183.85l3-10.8-3 10.8-.6 2.4" />
		<path d="M572.65 183.85l-.6 2.4-1.2 5.4" />
		<path d="M572.05 186.25l-1.2 5.4-21.6-5.4" />
		<path d="M570.85 191.65l-21.6-5.4 1.2-4.8" />
		<path d="M549.25 186.25l1.2-4.8 2.4-9" />
		<path d="M550.45 181.45l2.4-9 1.2-4.8" />
		<path d="M552.85 172.45l1.2-4.8 21.6 5.4M554.05 167.65l21.6 5.4M727.45 179.65l-62.4-15.6 62.4 15.6-4.2 18" />
		<path d="M727.45 179.65l-4.2 18-.6 3" />
		<path d="M723.25 197.65l-.6 3-3.6 12" />
		<path d="M722.65 200.65l-3.6 12-2.4 9" />
		<path d="M719.05 212.65l-2.4 9-6-1.8" />
		<path d="M716.65 221.65l-6-1.8h-.6M710.65 219.85h-.6l-10.2-2.4" />
		<path d="M710.05 219.85l-10.2-2.4-.6 3" />
		<path d="M699.85 217.45l-.6 3-1.8 5.4" />
		<path d="M699.25 220.45l-1.8 5.4-.6 4.2" />
		<path d="M697.45 225.85l-.6 4.2-2.4 9" />
		<path d="M696.85 230.05l-2.4 9-42-10.8" />
		<path d="M694.45 239.05l-42-10.8v-.6" />
		<path d="M652.45 228.25v-.6l2.4-10.2" />
		<path d="M652.45 227.65l2.4-10.2 1.2-4.8" />
		<path d="M654.85 217.45l1.2-4.8 1.8-6M656.05 212.65l1.8-6-1.8-.6M657.85 206.65l-3.6-1.2M656.05 206.05l-1.8-.6 1.2-3M654.25 205.45l1.2-3 .6-3.6" />
		<path d="M655.45 202.45l.6-3.6 1.2-3.6M656.05 198.85l1.2-3.6.6-3.6" />
		<path d="M657.25 195.25l.6-3.6 1.2-3.6M657.85 191.65l2.4-7.2M659.05 188.05l1.2-3.6.6-3.6" />
		<path d="M660.25 184.45l.6-3.6-3.6-1.2M660.85 180.85l-7.2-2.4M657.25 179.65l-3.6-1.2-3.6-.6" />
		<path d="M653.65 178.45l-3.6-.6h-1.2M650.05 177.85h-1.2l-2.4-1.2" />
		<path d="M648.85 177.85l-2.4-1.2h-.6M646.45 176.65h-1.2M645.85 176.65h-1.2M645.25 176.65h-.6l-3-1.2" />
		<path d="M644.65 176.65l-3-1.2-3.6-.6M641.65 175.45l-3.6-.6-2.4-.6" />
		<path d="M638.05 174.85l-2.4-.6-3-.6" />
		<path d="M635.65 174.25l-3-.6-3.6-1.2" />
		<path d="M632.65 173.65l-3.6-1.2 3-13.8" />
		<path d="M629.05 172.45l3-13.8 32.4 8.4" />
		<path d="M632.05 158.65l32.4 8.4.6-3M664.45 167.05l.6-3M847.45 243.25l-21-4.8 21 4.8-6.6 24.6" />
		<path d="M847.45 243.25l-6.6 24.6-.6-.6" />
		<path d="M840.85 267.85l-.6-.6-11.4-3" />
		<path d="M840.25 267.25l-11.4-3h-1.8M828.85 264.25h-1.8l-1.8-.6M827.05 264.25l-5.4-1.8M825.25 263.65l-3.6-1.2-2.4-.6M821.65 262.45l-28.8-7.2M819.25 261.85l-26.4-6.6h-1.2M792.85 255.25h-1.2l1.2-4.8M791.65 255.25l1.8-7.2M792.85 250.45l.6-2.4-6.6-1.8" />
		<path d="M793.45 248.05l-6.6-1.8.6-3" />
		<path d="M786.85 246.25l.6-3 4.8-19.2" />
		<path d="M787.45 243.25l4.8-19.2.6-3" />
		<path d="M792.25 224.05l.6-3 4.2-15.6" />
		<path d="M792.85 221.05l4.2-15.6 21 5.4" />
		<path d="M797.05 205.45l21 5.4 1.8.6" />
		<path d="M818.05 210.85l1.8.6 2.4.6" />
		<path d="M819.85 211.45l2.4.6 1.8.6" />
		<path d="M822.25 212.05l1.8.6 8.4 1.8" />
		<path d="M824.05 212.65l8.4 1.8-.6 1.2M832.45 214.45l-.6 1.2-3.6 14.4" />
		<path d="M831.85 215.65l-3.6 14.4-.6 3" />
		<path d="M828.25 230.05l-.6 3-.6 1.8" />
		<path d="M827.65 233.05l-.6 1.8-.6 3.6M827.05 234.85l-.6 3.6M956.65 287.65l19.8-77.4-19.8 77.4-3-1.2" />
		<path d="M956.65 287.65l-3-1.2-8.4-1.8" />
		<path d="M953.65 286.45l-8.4-1.8-7.2-1.8" />
		<path d="M945.25 284.65l-7.2-1.8-6.6-1.8" />
		<path d="M938.05 282.85l-6.6-1.8-3.6-1.2" />
		<path d="M931.45 281.05l-3.6-1.2-22.2-5.4" />
		<path d="M927.85 279.85l-22.2-5.4-7.2-1.8" />
		<path d="M905.65 274.45l-7.2-1.8-11.4-3" />
		<path d="M898.45 272.65l-11.4-3-1.8-.6M887.05 269.65l-1.8-.6-4.2-.6" />
		<path d="M885.25 269.05l-4.2-.6-.6-.6" />
		<path d="M881.05 268.45l-.6-.6h-.6" />
		<path d="M880.45 267.85h-.6l19.2-76.2" />
		<path d="M879.85 267.85l19.2-76.2.6-.6" />
		<path d="M899.05 191.65l.6-.6 60.6 15" />
		<path d="M899.65 191.05l60.6 15 16.2 4.2M960.25 206.05l16.2 4.2M787.45 334.45l-50.4-49.8 50.4 49.8 1.8 1.8M787.45 334.45l3 3M789.25 336.25l1.2 1.2 1.2.6" />
		<path d="M790.45 337.45l1.2.6 1.2 1.8" />
		<path d="M791.65 338.05l1.2 1.8 15.6 15.6M792.85 339.85l15.6 15.6-17.4 17.4" />
		<path d="M808.45 355.45l-17.4 17.4-72-70.2" />
		<path d="M791.05 372.85l-72-70.2 5.4-5.4M719.05 302.65l11.4-11.4M724.45 297.25l6-6-1.2-.6" />
		<path d="M730.45 291.25l-1.2-.6 3-3" />
		<path d="M729.25 290.65l3-3 1.2.6" />
		<path d="M732.25 287.65l1.2.6 3.6-3.6M733.45 288.25l3.6-3.6M810.25 443.65l7.2-7.2-7.2 7.2-18.6 19.2" />
		<path d="M810.25 443.65l-18.6 19.2-16.2-16.2" />
		<path d="M791.65 462.85l-16.2-16.2 25.8-26.4" />
		<path d="M775.45 446.65l25.8-26.4 4.8 4.8M801.25 420.25l6 6M806.05 425.05l5.4 5.4M807.25 426.25l4.8 4.8M811.45 430.45l6 6M812.05 431.05l5.4 5.4M693.85 566.05l15.6-19.2-15.6 19.2-19.2 16.2" />
		<path d="M693.85 566.05l-19.2 16.2-16.2 19.2" />
		<path d="M674.65 582.25l-16.2 19.2-5.4 5.4M658.45 601.45l-5.4 5.4v.6M653.05 606.85v.6l-12.6 11.4" />
		<path d="M653.05 607.45l-12.6 11.4-5.4 4.2" />
		<path d="M640.45 618.85l-5.4 4.2-22.2-21.6" />
		<path d="M635.05 623.05l-22.2-21.6 20.4-24.6" />
		<path d="M612.85 601.45l20.4-24.6 16.8-14.4" />
		<path d="M633.25 576.85l16.8-14.4 2.4-1.8" />
		<path d="M650.05 562.45l2.4-1.8 1.2-1.8M652.45 560.65l1.2-1.8.6-.6M653.65 558.85l.6-.6 14.4-16.8" />
		<path d="M654.25 558.25l14.4-16.8 16.8-15" />
		<path d="M668.65 541.45l16.8-15 1.8-1.8M685.45 526.45l3.6-3.6M687.25 524.65l1.8-1.8 3-3.6" />
		<path d="M689.05 522.85l3-3.6 11.4-13.8" />
		<path d="M692.05 519.25l11.4-13.8 24.6-21" />
		<path d="M703.45 505.45l24.6-21 21.6 22.2" />
		<path d="M728.05 484.45l21.6 22.2-8.4 10.2" />
		<path d="M749.65 506.65l-8.4 10.2-6.6 7.8" />
		<path d="M741.25 516.85l-6.6 7.8-1.2-.6" />
		<path d="M734.65 524.65l-1.2-.6-4.8 6.6" />
		<path d="M733.45 524.05l-4.8 6.6-19.2 16.2M728.65 530.65l-19.2 16.2M440.05 368.05l18-18-18 18-1.2 1.8" />
		<path d="M440.05 368.05l-1.2 1.8-6.6 6.6" />
		<path d="M438.85 369.85l-6.6 6.6-8.4 7.8" />
		<path d="M432.25 376.45l-8.4 7.8-5.4 6" />
		<path d="M423.85 384.25l-5.4 6-.6.6M418.45 390.25l-10.2 10.2M417.85 390.85l-9.6 9.6-9.6-9.6" />
		<path d="M408.25 400.45l-9.6-9.6-1.2-1.8M398.65 390.85l-1.2-1.8-15-14.4" />
		<path d="M397.45 389.05l-15-14.4h-.6M382.45 374.65h-.6l19.2-19.2" />
		<path d="M381.85 374.65l19.2-19.2 1.2-1.8" />
		<path d="M401.05 355.45l1.2-1.8 7.8-7.8M402.25 353.65l22.2-22.2M410.05 345.85l22.2-22.2M424.45 331.45l7.8-7.8 1.2-1.8" />
		<path d="M432.25 323.65l1.2-1.8 19.2-19.2" />
		<path d="M433.45 321.85l19.2-19.2 25.8 25.8M452.65 302.65l25.8 25.8h.6" />
		<path d="M478.45 328.45h.6l-10.2 10.8" />
		<path d="M479.05 328.45l-10.2 10.8-1.8 1.2" />
		<path d="M468.85 339.25l-1.8 1.2-2.4 2.4" />
		<path d="M467.05 340.45l-2.4 2.4-1.2 1.8M464.65 342.85l-1.2 1.8-5.4 5.4M463.45 344.65l-5.4 5.4M480.85 462.85l-3-3.6 3 3.6 2.4 1.8" />
		<path d="M480.85 462.85l2.4 1.8 10.2 10.2" />
		<path d="M483.25 464.65l10.2 10.2-4.8 4.8M493.45 474.85l-4.8 4.8-18 18.6M488.65 479.65l-18 18.6-22.2-22.2M470.65 498.25l-30-30M448.45 476.05l-13.2-13.2M440.65 468.25l-10.2-10.2M435.25 462.85l-4.8-4.8h-.6M430.45 458.05h-.6l-1.8-1.8M429.85 458.05l-5.4-5.4M428.05 456.25l-3.6-3.6 2.4-2.4M424.45 452.65l9.6-9.6M426.85 450.25l7.2-7.2 4.8-5.4" />
		<path d="M434.05 443.05l4.8-5.4 8.4-8.4" />
		<path d="M438.85 437.65l8.4-8.4 30.6 30M447.25 429.25l30.6 30M422.05 465.85l-30-29.4 30 29.4 2.4-1.8" />
		<path d="M422.05 465.85l2.4-1.8-1.8-2.4" />
		<path d="M424.45 464.05l-1.8-2.4-1.8-1.8M422.65 461.65l-1.8-1.8 3-3" />
		<path d="M420.85 459.85l3-3 1.8 1.8" />
		<path d="M423.85 456.85l1.8 1.8 2.4 1.8" />
		<path d="M425.65 458.65l2.4 1.8 1.8-2.4M428.05 460.45l1.8-2.4h.6" />
		<path d="M429.85 458.05h.6l4.8 4.8" />
		<path d="M430.45 458.05l4.8 4.8-7.8 7.8M435.25 462.85l-7.8 7.8 5.4 6" />
		<path d="M427.45 470.65l5.4 6-12 12M432.85 476.65l-22.8 22.8" />
		<path d="M420.85 488.65l-10.8 10.8-36-35.4" />
		<path d="M410.05 499.45l-36-35.4-5.4-4.8" />
		<path d="M374.05 464.05l-5.4-4.8-7.2-7.2M368.65 459.25l-8.4-8.4M361.45 452.05l-7.2-7.2M360.25 450.85l-6-6-21-20.4" />
		<path d="M354.25 444.85l-21-20.4.6-.6M333.25 424.45l5.4-5.4M333.85 423.85l5.4-5.4M338.65 419.05l.6-.6 6.6-7.2" />
		<path d="M339.25 418.45l6.6-7.2 1.8-1.2" />
		<path d="M345.85 411.25l1.8-1.2 8.4-9" />
		<path d="M347.65 410.05l8.4-9 .6.6M356.05 401.05l.6.6v-.6M356.65 401.65v-1.2M356.65 401.05v-.6l3.6-4.2" />
		<path d="M356.65 400.45l3.6-4.2 3-3M360.25 396.25l21-21M363.25 393.25l18-18h.6M381.25 375.25h.6l.6-.6" />
		<path d="M381.85 375.25l.6-.6 15 14.4" />
		<path d="M382.45 374.65l15 14.4-1.2 1.2M397.45 389.05l-1.2 1.2h.6M396.25 390.25h.6l-6.6 19.8M396.85 390.25l-6.6 19.8 9.6 3M390.25 410.05l9.6 3-1.8 5.4" />
		<path d="M399.85 413.05l-1.8 5.4-4.8-1.8" />
		<path d="M398.05 418.45l-4.8-1.8-4.8 13.8" />
		<path d="M393.25 416.65l-4.8 13.8-1.2-.6" />
		<path d="M388.45 430.45l-1.2-.6-1.2 1.2" />
		<path d="M387.25 429.85l-1.2 1.2 6 5.4M386.05 431.05l6 5.4M546.25 547.45l-38.4-37.2 38.4 37.2-21.6 22.2" />
		<path d="M546.25 547.45l-21.6 22.2-1.8-1.2" />
		<path d="M524.65 569.65l-1.8-1.2-3 3M522.85 568.45l-5.4 5.4M519.85 571.45l-2.4 2.4 1.2 1.2" />
		<path d="M517.45 573.85l1.2 1.2-9 9.6" />
		<path d="M518.65 575.05l-9 9.6-13.8-13.2" />
		<path d="M509.65 584.65l-13.8-13.2 2.4-3" />
		<path d="M495.85 571.45l2.4-3 1.2-.6" />
		<path d="M498.25 568.45l1.2-.6-1.8-1.8" />
		<path d="M499.45 567.85l-1.8-1.8-3-2.4M497.65 566.05l-3-2.4-18-18" />
		<path d="M494.65 563.65l-18-18 7.2-7.2" />
		<path d="M476.65 545.65l7.2-7.2.6.6" />
		<path d="M483.85 538.45l.6.6 4.2-4.2" />
		<path d="M484.45 539.05l4.2-4.2-2.4-2.4" />
		<path d="M488.65 534.85l-2.4-2.4 20.4-20.4" />
		<path d="M486.25 532.45l20.4-20.4 1.2-1.8M506.65 512.05l1.2-1.8M463.45 604.45l2.4-2.4-2.4 2.4h-.6M463.45 604.45h-.6l-3 3.6" />
		<path d="M462.85 604.45l-3 3.6-9 8.4" />
		<path d="M459.85 608.05l-9 8.4-5.4 6" />
		<path d="M450.85 616.45l-5.4 6-8.4 8.4" />
		<path d="M445.45 622.45l-8.4 8.4-6 6.6" />
		<path d="M437.05 630.85l-6 6.6-2.4-2.4M431.05 637.45l-5.4-5.4M428.65 635.05l-3-3-4.2-3.6" />
		<path d="M425.65 632.05l-4.2-3.6-3.6-3.6M421.45 628.45l-4.8-4.8M417.85 624.85l-15-15M416.65 623.65l-13.8-13.8 10.2-10.2" />
		<path d="M402.85 609.85l10.2-10.2-4.2-3.6" />
		<path d="M413.05 599.65l-4.2-3.6 3.6-3.6M408.85 596.05l7.2-7.2M412.45 592.45l5.4-5.4M416.05 588.85l1.8-1.8 22.2-22.8" />
		<path d="M417.85 587.05l22.2-22.8 8.4 8.4M440.05 564.25l12.6 12.6M448.45 572.65l4.2 4.2 4.2 3.6" />
		<path d="M452.65 576.85l4.2 3.6 10.2 9.6" />
		<path d="M456.85 580.45l10.2 9.6 4.8 4.8M467.05 590.05l5.4 5.4M471.85 594.85l.6.6-6.6 6.6M472.45 595.45l-6.6 6.6M601.45 658.45l8.4-8.4-8.4 8.4-8.4 9" />
		<path d="M601.45 658.45l-8.4 9-.6.6" />
		<path d="M593.05 667.45l-.6.6h-.6M592.45 668.05h-1.2M591.85 668.05h-.6l-1.2-.6" />
		<path d="M591.25 668.05l-1.2-.6-14.4-14.4M590.05 667.45l-14.4-14.4h-.6M575.65 653.05h-.6v-1.2M575.05 653.05v-1.8" />
		<path d="M575.05 651.85v-.6l.6-.6" />
		<path d="M575.05 651.25l.6-.6 16.8-17.4" />
		<path d="M575.65 650.65l16.8-17.4 17.4 16.8M592.45 633.25l17.4 16.8M396.25 834.25l83.4-43.2-83.4 43.2-5.4-10.8" />
		<path d="M396.25 834.25l-5.4-10.8 11.4-5.4" />
		<path d="M390.85 823.45l11.4-5.4 1.2-.6" />
		<path d="M402.25 818.05l1.2-.6 9-4.8" />
		<path d="M403.45 817.45l9-4.8.6-.6" />
		<path d="M412.45 812.65l.6-.6 5.4-2.4" />
		<path d="M413.05 812.05l5.4-2.4 1.2-.6" />
		<path d="M418.45 809.65l1.2-.6 12.6-6.6" />
		<path d="M419.65 809.05l12.6-6.6-5.4-9" />
		<path d="M432.25 802.45l-5.4-9 1.2-3.6" />
		<path d="M426.85 793.45l1.2-3.6 13.8-7.2" />
		<path d="M428.05 789.85l13.8-7.2 3.6.6" />
		<path d="M441.85 782.65l3.6.6 4.8 9.6" />
		<path d="M445.45 783.25l4.8 9.6 24-12" />
		<path d="M450.25 792.85l24-12 32.4-33" />
		<path d="M474.25 780.85l32.4-33 1.2-1.2" />
		<path d="M506.65 747.85l1.2-1.2 47.4-48.6" />
		<path d="M507.85 746.65l47.4-48.6-3-6.6" />
		<path d="M555.25 698.05l-3-6.6-8.4-15.6" />
		<path d="M552.25 691.45l-8.4-15.6 7.8-4.2" />
		<path d="M543.85 675.85l7.8-4.2 15 15" />
		<path d="M551.65 671.65l15 15 2.4-2.4" />
		<path d="M566.65 686.65l2.4-2.4 4.8 4.8" />
		<path d="M569.05 684.25l4.8 4.8v.6M573.85 689.05v.6l1.8 1.8M573.85 689.65l2.4 2.4M575.65 691.45l.6.6-15 15" />
		<path d="M576.25 692.05l-15 15-81.6 84M561.25 707.05l-81.6 84M305.05 881.05l83.4-43.2-83.4 43.2-21.6-43.2" />
		<path d="M305.05 881.05l-21.6-43.2h1.2" />
		<path d="M283.45 837.85h1.2l7.8-4.2" />
		<path d="M284.65 837.85l7.8-4.2 16.8 32.4" />
		<path d="M292.45 833.65l16.8 32.4 73.8-38.4" />
		<path d="M309.25 866.05l73.8-38.4 5.4 10.2M383.05 827.65l5.4 10.2M282.85 780.85l46.8-32.4-46.8 32.4-.6-1.2" />
		<path d="M282.85 780.85l-.6-1.2-9-12.6" />
		<path d="M282.25 779.65l-9-12.6-1.2-1.8" />
		<path d="M273.25 767.05l-1.2-1.8-3.6-4.8" />
		<path d="M272.05 765.25l-3.6-4.8 1.2-1.2" />
		<path d="M268.45 760.45l1.2-1.2 3.6-2.4" />
		<path d="M269.65 759.25l3.6-2.4 6.6-4.8" />
		<path d="M273.25 756.85l6.6-4.8 4.8-3M279.85 752.05l4.8-3 49.2-34.2" />
		<path d="M284.65 749.05l49.2-34.2 1.8 1.8" />
		<path d="M333.85 714.85l1.8 1.8 1.2 2.4" />
		<path d="M335.65 716.65l1.2 2.4 4.8 7.2" />
		<path d="M336.85 719.05l4.8 7.2 6.6 9.6M341.65 726.25l6.6 9.6-12 8.4" />
		<path d="M348.25 735.85l-12 8.4-1.8 1.2" />
		<path d="M336.25 744.25l-1.8 1.2-3 1.8" />
		<path d="M334.45 745.45l-3 1.8-1.8 1.2M331.45 747.25l-1.8 1.2M274.45 717.85l5.4-3.6-5.4 3.6-.6.6" />
		<path d="M274.45 717.85l-.6.6-19.2 13.2" />
		<path d="M273.85 718.45l-19.2 13.2-2.4-3" />
		<path d="M254.65 731.65l-2.4-3-3.6-5.4" />
		<path d="M252.25 728.65l-3.6-5.4h-.6M248.65 723.25h-.6v-.6M248.05 723.25v-.6l-1.2-1.8" />
		<path d="M248.05 722.65l-1.2-1.8-1.8-1.8" />
		<path d="M246.85 720.85l-1.8-1.8v-.6" />
		<path d="M245.05 719.05v-.6l-8.4-12" />
		<path d="M245.05 718.45l-8.4-12 14.4-10.2" />
		<path d="M236.65 706.45l14.4-10.2 3-1.8" />
		<path d="M251.05 696.25l3-1.8 1.2-1.2" />
		<path d="M254.05 694.45l1.2-1.2 7.8-5.4" />
		<path d="M255.25 693.25l7.8-5.4 7.2 10.2" />
		<path d="M263.05 687.85l7.2 10.2 3 4.2" />
		<path d="M270.25 698.05l3 4.2 1.8 3" />
		<path d="M273.25 702.25l1.8 3 1.2 1.8" />
		<path d="M275.05 705.25l1.2 1.8.6.6" />
		<path d="M276.25 707.05l.6.6 3 4.2" />
		<path d="M276.85 707.65l3 4.2 1.2 1.8" />
		<path d="M279.85 711.85l1.2 1.8-1.2.6M281.05 713.65l-1.2.6M306.85 443.05l-55.2-141.6 55.2 141.6-70.2 27" />
		<path d="M306.85 443.05l-70.2 27-3.6-9M236.65 470.05l-3.6-9-4.2-10.2" />
		<path d="M233.05 461.05l-4.2-10.2-1.2-3.6" />
		<path d="M228.85 450.85l-1.2-3.6-.6-2.4" />
		<path d="M227.65 447.25l-.6-2.4-21 8.4" />
		<path d="M227.05 444.85l-21 8.4-1.2-3" />
		<path d="M206.05 453.25l-1.2-3-6.6 2.4M204.85 450.25l-6.6 2.4-4.8-6.6" />
		<path d="M198.25 452.65l-4.8-6.6 7.2-6.6" />
		<path d="M193.45 446.05l7.2-6.6-4.2-11.4" />
		<path d="M200.65 439.45l-4.2-11.4-1.8-4.8" />
		<path d="M196.45 428.05l-1.8-4.8-19.2-49.2" />
		<path d="M194.65 423.25l-19.2-49.2-1.2-3" />
		<path d="M175.45 374.05l-1.2-3-6.6 2.4" />
		<path d="M174.25 371.05l-6.6 2.4-4.8-6.6" />
		<path d="M167.65 373.45l-4.8-6.6 7.2-6.6" />
		<path d="M162.85 366.85l7.2-6.6-6.6-16.2M170.05 360.25l-6.6-16.2-1.8-5.4M163.45 344.05l-1.8-5.4-2.4-6M161.65 338.65l-2.4-6 21-7.8" />
		<path d="M159.25 332.65l21-7.8v-.6M180.25 324.85v-.6l70.2-27" />
		<path d="M180.25 324.25l70.2-27v.6M250.45 297.25v.6l1.2 3.6M250.45 297.85l1.2 3.6M335.05 341.05l-44.4-115.2 44.4 115.2-12 4.2M335.05 341.05l-12 4.2.6 1.8" />
		<path d="M323.05 345.25l.6 1.8 1.2 3M323.65 347.05l1.2 3v.6M324.85 350.05v1.2M324.85 350.65v1.2M324.85 351.25v.6l-.6 1.2" />
		<path d="M324.85 351.85l-.6 1.2-.6.6M324.25 353.05l-1.2 1.2M323.65 353.65l-.6.6h-.6M323.05 354.25h-1.8" />
		<path d="M322.45 354.25h-1.2l-.6-.6M321.25 354.25l-1.2-1.2M320.65 353.65l-.6-.6h-.6" />
		<path d="M320.05 353.05h-.6v-.6M319.45 353.05v-1.2M319.45 352.45v-.6l-.6-1.2" />
		<path d="M319.45 351.85l-.6-1.2-.6-1.8" />
		<path d="M318.85 350.65l-.6-1.8-.6-1.2" />
		<path d="M318.25 348.85l-.6-1.2-12.6 4.8M317.65 347.65l-12.6 4.8-36-93" />
		<path d="M305.05 352.45l-36-93 2.4-.6" />
		<path d="M269.05 259.45l2.4-.6-2.4-5.4" />
		<path d="M271.45 258.85l-2.4-5.4-1.8 1.2M269.05 253.45l-1.8 1.2-10.2-26.4" />
		<path d="M267.25 254.65l-10.2-26.4 4.2-1.8" />
		<path d="M257.05 228.25l4.2-1.8 12.6-4.8" />
		<path d="M261.25 226.45l12.6-4.8 3.6 9" />
		<path d="M273.85 221.65l3.6 9 13.2-4.8M277.45 230.65l13.2-4.8M243.25 263.65l-39.6-102 39.6 102-19.8 7.8" />
		<path d="M243.25 263.65l-19.8 7.8-1.2-3.6" />
		<path d="M223.45 271.45l-1.2-3.6-1.2-3" />
		<path d="M222.25 267.85l-1.2-3v-.6M221.05 264.85v-.6l-1.8.6" />
		<path d="M221.05 264.25l-1.8.6-7.2 3" />
		<path d="M219.25 264.85l-7.2 3-4.2 1.2" />
		<path d="M212.05 267.85l-4.2 1.2-.6-1.2" />
		<path d="M207.85 269.05l-.6-1.2-34.8-88.8" />
		<path d="M207.25 267.85l-34.8-88.8-1.2-4.2" />
		<path d="M172.45 179.05l-1.2-4.2 4.2-1.8M171.25 174.85l4.2-1.8-.6-1.2" />
		<path d="M175.45 173.05l-.6-1.2-.6-1.8M174.85 171.85l-1.8-5.4M174.25 170.05l-1.2-3.6-1.2-3M173.05 166.45l-1.2-3 1.2-6" />
		<path d="M171.85 163.45l1.2-6 2.4-7.8" />
		<path d="M173.05 157.45l2.4-7.8-9-2.4" />
		<path d="M175.45 149.65l-9-2.4 25.8-99" />
		<path d="M166.45 147.25l25.8-99 10.8 2.4" />
		<path d="M192.25 48.25l10.8 2.4h.6M203.05 50.65h.6l2.4.6" />
		<path d="M203.65 50.65l2.4.6.6-3.6" />
		<path d="M206.05 51.25l.6-3.6 1.2-3.6" />
		<path d="M206.65 47.65l1.2-3.6 20.4 5.4" />
		<path d="M207.85 44.05l20.4 5.4-27.6 106.2" />
		<path d="M228.25 49.45l-27.6 106.2-1.2 5.4" />
		<path d="M200.65 155.65l-1.2 5.4.6 2.4" />
		<path d="M199.45 161.05l.6 2.4 3.6-1.8M200.05 163.45l3.6-1.8M129.85 287.05l-6-15.6 6 15.6v1.2M129.85 287.05v1.2l3 6" />
		<path d="M129.85 288.25l3 6 .6 1.8M132.85 294.25l1.2 3.6M133.45 296.05l.6 1.8-1.8.6M134.05 297.85l-1.8.6-4.2 1.8" />
		<path d="M132.25 298.45l-4.2 1.8-16.8 6.6" />
		<path d="M128.05 300.25l-16.8 6.6-1.2.6M111.25 306.85l-1.2.6-1.8.6" />
		<path d="M110.05 307.45l-1.8.6h-1.2M108.25 308.05h-1.2l-19.2 7.8" />
		<path d="M107.05 308.05l-19.2 7.8-7.8-20.4" />
		<path d="M87.85 315.85l-7.8-20.4 7.2-3M80.05 295.45l7.2-3-10.8-27" />
		<path d="M87.25 292.45l-10.8-27-3 1.2" />
		<path d="M76.45 265.45l-3 1.2-.6-1.8" />
		<path d="M73.45 266.65l-.6-1.8-1.8-4.2" />
		<path d="M72.85 264.85l-1.8-4.2-4.8-12.6" />
		<path d="M71.05 260.65l-4.8-12.6 9-3.6" />
		<path d="M66.25 248.05l9-3.6 2.4-.6M75.25 244.45l2.4-.6 6-2.4" />
		<path d="M77.65 243.85l6-2.4 1.2-.6" />
		<path d="M83.65 241.45l1.2-.6 3.6-1.2M84.85 240.85l3.6-1.2 2.4-1.2M88.45 239.65l2.4-1.2 7.2-3" />
		<path d="M90.85 238.45l7.2-3 10.2-3.6" />
		<path d="M98.05 235.45l10.2-3.6.6 1.2" />
		<path d="M108.25 231.85l.6 1.2 1.8 4.8" />
		<path d="M108.85 233.05l1.8 4.8 1.2 3.6M110.65 237.85l1.2 3.6.6.6M111.85 241.45l.6.6 1.2 3" />
		<path d="M112.45 242.05l1.2 3 4.2 11.4" />
		<path d="M113.65 245.05l4.2 11.4 1.2 3.6" />
		<path d="M117.85 256.45l1.2 3.6 3.6 8.4" />
		<path d="M119.05 260.05l3.6 8.4 1.2 3M122.65 268.45l1.2 3M62.65 217.45l88.2-164.4-88.2 164.4-16.2-9" />
		<path d="M62.65 217.45l-16.2-9 5.4-10.2" />
		<path d="M46.45 208.45l5.4-10.2 5.4-9.6" />
		<path d="M51.85 198.25l5.4-9.6 1.8-3.6" />
		<path d="M57.25 188.65l1.8-3.6 3-5.4" />
		<path d="M59.05 185.05l3-5.4 54.6-102.6" />
		<path d="M62.05 179.65l54.6-102.6 1.2-1.8M116.65 77.05l1.2-1.8 4.2-7.8" />
		<path d="M117.85 75.25l4.2-7.8 1.8-3" />
		<path d="M122.05 67.45l1.8-3 8.4-15.6" />
		<path d="M123.85 64.45l8.4-15.6 2.4-4.8" />
		<path d="M132.25 48.85l2.4-4.8 16.2 9M134.65 44.05l16.2 9M59.05 143.65l32.4-36.6-32.4 36.6.6.6" />
		<path d="M59.05 143.65l.6.6-.6.6" />
		<path d="M59.65 144.25l-.6.6-7.2 8.4" />
		<path d="M59.05 144.85l-7.2 8.4-7.2-7.2" />
		<path d="M51.85 153.25l-7.2-7.2 7.8-8.4" />
		<path d="M44.65 146.05l7.8-8.4.6.6M52.45 137.65l.6.6 32.4-36.6" />
		<path d="M53.05 138.25l32.4-36.6 6 5.4M85.45 101.65l6 5.4" />
		</g>
	</g>
	
	</g>
	)
}

export default MapMT
